import qrcode from "../../images/events/trad-sun/menu-qr-code.jpg"

const QRCode = () => {
    return (
        <div id="qr-case">
            <a href="https://online.publuu.com/616092/1377569" target="_blank">
                <figure className='image 1by1' >
                    <img src={qrcode} id='trad-sun-qr'></img>
                </figure>
                <p id="qr-text">Click Here or Scan for More Info</p>
            </a>
        </div>
    )
}

export default QRCode