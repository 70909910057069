import poster from "../../images/events/trad-sun/trad-sun-sept24-0.jpg"

const Poster = () => {
    return (
        <div id="upcoming-trad-pic-container">
            <figure className="image 5by3" id="upcoming-trad-pic">
                <img src={poster}></img>
            </figure>
        </div>
    )
}

export default Poster