import PackageDescription from "../Leadership/PackageDescription"
import Info from "./Info"
import Payment from "./Payment"
import { useState, useEffect } from "react"

import Poster from "./Poster"
import raffle from "../../images/events/trad-sun/sept24-raffle.jpg"
import essentials from "../../images/events/trad-sun/essentials.png"

const UpcomingTrad = ({event}) => {


    const couples = event.hasCouples
    const vip = event.hasVIP

    var cost = `US${event.cost} per person`
    
    if (event.hasCouples){
        cost += ` | US${event.couplesCost} couples`
    }
    if (event.hasVIP){
        cost += ` | US${event.VIPcost} couples`
    }

    return (
        <div className="card" id="upcoming-trad">
            <h2 className="title is-4" id="upcoming-trad-title">Harare, September 2024 </h2>
            <div className="columns">
                <div className="column is-half">
                    <Poster />
                </div>
                <div className="column is-half">
                    <Info event={event} cost={cost}/>
                </div>
            </div>

            {/* <div className="columns">
                <div className="column is-half">
                    <figure className="image">
                        <img src={raffle}></img>
                    </figure>
                </div>
                <div className="column is-half">
                    <figure className="image">
                        <img src={essentials}></img>
                    </figure>
                </div>
            </div> */}
            {/* <Payment /> */}
            
        </div>
    )
}

export default UpcomingTrad