import PreviousTradImage from "./PreviousTradImage"
import Button from "../Generics/Button"

const PreviousTrad = () => {
    return (
        <div className="card" id="previous-trad">
            <div className="columns">
                <div className="column is-5">
                    <h2 className="subtitle is-5" id="previous-trad-title"><u>Windhoek, June 2024</u></h2>
                    <p id="previous-trad-para">Fun was had</p>
                    <Button text={"See more"} path_redirect={"/gallery"} />       
                </div>
                <div className="column is-7" >
                    <PreviousTradImage />
                </div>
            </div>
            
        </div>
    )
}

export default PreviousTrad