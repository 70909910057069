import TraditionalSlides from "./TraditionalSlides"
import UpcomingTrad from "./UpcomingTrad"
import PreviousTrad from "./PreviousTrad"

const TraditionalSunday = ({infos}) => {

    const events = [
        {
            "id": 1,
            "name": "Harare, September 2024",
            "city": "Harare",
            "country": "Zimbabwe",
            "description": "1st of September, 1st Zim Edition! You don't wanna miss out on this one. \r\n\r\nEnjoy a buffet of a range of Afro-centric dishes :)",
            "cost": 20,
            "hasCouples": true,
            "couplesCost": 30,
            "hasVIP": false,
            "VIPcost": 0,
        },
    ]

    return (
        <div className="box" id="traditionals">
            <h2 className="title is-2" id="traditionals-title">
                Traditional Sundays
            </h2>
            <TraditionalSlides />
            <p id="traditionals-para">{infos.info}</p>
            
            <h2 className="subtitle is-4" id="trad-sun-upcoming-title">Next on Traditional Sunday...</h2>
            <UpcomingTrad event={events.find(item => item.name === "Harare, September 2024")}/>

            <h2 className="subtitle is-4" id="trad-sun-coming-title">Previously on Traditional Sunday...</h2>
            
            <PreviousTrad />
            {/* Put in gallery here */}
        </div>
    )
}

export default TraditionalSunday