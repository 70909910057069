import QRCode from "./QRCode"

const Info = ({event, cost}) => {
  return (
    <div>
        <p id="upcoming-trad-para">{event.description}</p>
        <br />
        <p className="subtitke is-5" id="upcoming-trad-price">Prices:</p>
        <p className="subtitle is-5" id="upcoming-trad-price">{cost}</p>
        <p className="subtitle is-6" id="upcoming-trad-info">Bar Available</p>
        <p className="subtitle is-6" id="upcoming-trad-info">Cooler Boxes Allowed</p>

        <QRCode />
    </div>
  )
}

export default Info